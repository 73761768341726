<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-date-picker
          size="small"
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          v-model="dateRange"
          type="monthrange"
          range-separator="至"
          value-format="timestamp"
          start-placeholder="统计开始月份"
          end-placeholder="统计结束月份"
        />
        <el-date-picker
          v-model="dateRange2"
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          end-placeholder="发单结束日期"
          range-separator="至"
          size="small"
          value-format="timestamp"
          start-placeholder="发单开始日期"
          type="daterange"
        />
        <el-date-picker
          v-model="dateRange3"
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          end-placeholder="计划交期结束日期"
          range-separator="至"
          size="small"
          value-format="timestamp"
          start-placeholder="计划交期开始日期"
          type="daterange"
        />
        <!--        <el-date-picker
          size="small"
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          v-model="dateRange4"
          type="daterange"
          range-separator="至"
          start-placeholder="实际交期开始日期"
          end-placeholder="实际交期结束日期"
        />-->
        <el-button type="primary" size="small" class="vd_export" @click="search()">查询</el-button>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()" icon="el-icon-refresh-right"> 刷新 </el-button>
        <el-button type="success" size="small" class="vd_export" @click="exportExcel()"> 导出Excel </el-button>
      </div>
      <DynamicUTable
        ref="partMultiTable"
        :tableData="tableData"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :page-size="50"
        :need-fixed-height="true"
        :need-search="false"
        :need-check-box="false"
        :editActived="editActived"
        :editClosed="editClosed"
        :row-click="row => (currentRow = row)"
        :show-summary="true"
        :summariesColumns="['order_sum', 'modr_part_count', 'modr_sum', 'modr_price_sum']"
        @getTableData="getDataList"
      >
      </DynamicUTable>
    </el-card>
  </div>
</template>

<script>
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { cloneDeep } from 'lodash';
import { keepNumber } from '@assets/js/regExUtil';
import { getNoCatch } from '@api/request';
import { tableProperties } from '@/views/StatisticsManagement/MPCStatistics/mpcs';
import { modrAPI } from '@api/modules/modr';
import { downloadFile, setTime } from '@api/public';

export default {
  name: 'MPC Statistics',
  components: {
    DynamicUTable
  },
  data() {
    return {
      dateRange: [],
      dateRange2: [],
      dateRange3: [],
      dateRange4: [],
      tableProperties: cloneDeep(tableProperties),
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      currentRow: {},
      editActivedRow: {}
    };
  },
  mounted() {
    this.initData();
  },
  beforeDestroy() {
    this.saveRow(this.currentRow);
  },
  methods: {
    keepNumber,
    initData() {
      this.getDataList();
    },
    editClosed({ row }) {
      if (JSON.stringify(this.editActivedRow) !== JSON.stringify(row)) this.saveRow(row);
    },
    editActived({ row }) {
      this.editActivedRow = cloneDeep(row);
    },
    async search() {
      await (this.$refs.partMultiTable.currentPage = 1);
      await (this.$refs.partMultiTable.searchForm.page_no = 1);
      await this.getDataList();
    },
    //获取数据
    async getDataList() {
      this.loadFlag = true;
      let searchForm = cloneDeep(this.$refs.partMultiTable.searchForm);
      setTime(searchForm, 'startTime', 'endTime', this.dateRange || [], true);
      setTime(searchForm, 'startTime2', 'endTime2', this.dateRange2 || [], false);
      setTime(searchForm, 'startTime3', 'endTime3', this.dateRange3 || [], true);
      // setTime(searchForm, 'startTime4', 'endTime4', this.dateRange4 || [], true);
      await getNoCatch(modrAPI.get_modr_statistics, searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.loadFlag = false;
      });
    },
    //跨分页导出excel
    exportExcel() {
      let searchForm = cloneDeep(this.$refs.partMultiTable.searchForm);
      setTime(searchForm, 'startTime', 'endTime', this.dateRange || [], true);
      setTime(searchForm, 'startTime2', 'endTime2', this.dateRange2 || [], false);
      setTime(searchForm, 'startTime3', 'endTime3', this.dateRange3 || [], true);
      searchForm.type = 1;
      modrAPI.exportExcel(searchForm).then(({ data }) => {
        downloadFile({ fileUrl: data.url, fileName: data.fileName, type: '.xlsx' });
      });
    },
    //刷新
    buttonRefresh() {
      if (JSON.stringify(this.currentRow) !== '{}') {
        this.saveRow(this.currentRow);
      }
      this.$refs.partMultiTable.resetFields();
      this.dateRange = [];
      this.dateRange2 = [];
      this.dateRange3 = [];
      this.getDataList();
    },
    async saveRow(row) {
      let cr = row ? row : this.currentRow;
      if (!cr || cr === {}) return;
      let isNew = cr.modr_statistics_id;
      let { code, data } = isNew ? await modrAPI.edit_modr_statistics(cr) : await modrAPI.add_modr_statistics(cr);
      if (code === 0) cr.modr_statistics_id = data.form_id;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
